var hostname = window.location.hostname;
var isProd = hostname === 'snail.qcplay.co.jp';
var disable = isProd ? false : true;
window.__bl = {
  config: {
      pid:"egrrccpv71@48487a99715af8c",
      appType:"web",
      imgUrl:"https://arms-retcode.aliyuncs.com/r.png?",
      sendResource:true,
      enableLinkTrace:true,
      behavior:true,
      disabled: disable,
      environment: isProd ? 'prod' : 'local',
      page: window.pageName,
  }
}
